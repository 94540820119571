/**
 * External resources
 */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

/**
  * Ant Design styles
  */
@import "~antd/dist/antd.less";

/**
  * Ant Design custom theme fixes
  */

// Layout

.ant-layout {
  min-height: 100vh;
}

// Inputs
.ant-input,
.ant-input-affix-wrapper {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-bottom-width: 2px;
}

.ant-modal-header {
  border: none;
  position: relative;
}

.ant-modal-header::before {
  content: "";
  width: 100%;
  height: 5px;
  border-radius: 50px;
  background-image: linear-gradient(to right, #7dceff 0%, #3f83fe 29%, #82d3ff 98%);
  position: absolute;
  top: 0;
  left: 0;
}

.ant-modal-title {
  color: #4e4e4e;
  font-weight: bold;
}

.ant-modal-close {
  path {
    fill: #4e4e4e;
  }
}

.ant-notification-notice-description {
  color: black;
}

.ant-radio-button {
  &-wrapper,
  &-wrapper:first-child,
  &-wrapper:last-child {
    border-radius: 9px;
    margin-left: 10px;
    margin-bottom: 10px;
    border-width: 1px;

    &::before {
      display: none !important;
    }
  }
}

.ant-form-item-label label {
  margin-left: 11px !important;
}

.ant-btn-primary {
  background-color: #5579B7;
  border-color: #5579B7;

  &:hover {
    background-color: #5579B7;
    border-color: #5579B7;
  }
}

@primary-color: #FF943B;@body-background: #FFFFFF;@success-color: #009A1D;@warning-color: #FF943B;@error-color: #D50808;@heading-color: #FF943B;@text-color: #FFFFFF;@text-color-secondary: #FF943B;@box-shadow-base: 20px 20px 40px #0000000A;@font-family: "Open Sans", sans-serif;@border-radius-base: 5px;